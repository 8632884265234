(function(n) {
    var f = function(d) { return document.querySelectorAll(d)[0] },
        d = function(d, f) { return d ? d[f] || "" : "" },
        v = function(d, f) { if (d)
                for (var e = d.length, h = 0; h < e; h++) f(h, d[h]) },
        x = function(d, f, e) { f = [f.toLowerCase(), "webkit".concat(f), "MS".concat(f), "o".concat(f)];
            v(f, function(h, f) { d[f] = e }) },
        C = function(d, f, e) { e = [e.toLowerCase(), "webkit".concat(e), "MS".concat(e), "o".concat(e)];
            v(e, function(e, m) { d.addEventListener(m, f, !1) }) },
        M = function(d, f) { C(d, f, "AnimationEnd") },
        R = function(d, f) {
            d.transitionEndEvent || (d.transitionEndEvent = !0, C(d, f, "TransitionEnd"))
        },
        F = function(d, f) { d.firstChild ? d.insertBefore(f, d.firstChild) : d.appendChild(f) },
        l = function(m, l) {
            var e = this,
                h = function(a, c) { var b = function(a) { return "undefined" !== typeof a }; return c ? b(l[a]) ? b(l[a][c]) ? l[a][c] : E[a][c] : E[a][c] : b(l[a]) ? l[a] : E[a] },
                L = function(a, c) {
                    try {
                        if (c) {
                            if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) document.exitFullscreen ? document.exitFullscreen()["catch"](function() {}) : document.mozCancelFullScreen ?
                                document.mozCancelFullScreen()["catch"](function() {}) : document.mozCancelFullScreen && document.mozCancelFullScreen()["catch"](function() {})
                        } else if (a.requestFullScreen) a.requestFullScreen();
                        else if (a["ms".concat("RequestFullScreen")]) a["ms".concat("RequestFullScreen")]();
                        else if (a["moz".concat("RequestFullScreen")]) a["moz".concat("RequestFullScreen")]();
                        else if (a["webkit".concat("RequestFullScreen")]) a["webkit".concat("RequestFullScreen")]()
                    } catch (b) { console.warn("[Zuck.js] Can't access fullscreen") }
                },
                w = function(a, c, b, e) { var d = 0 < c ? 1 : -1;
                    d *= Math.abs(c) / f("#zuck-modal").offsetWidth * 90; if (h("cubeEffect")) { var g = 0 === d ? "scale(0.95)" : "scale(0.930,0.930)";
                        x(f("#zuck-modal-content").style, "Transform", g); if (-90 > d || 90 < d) return !1 } c = h("cubeEffect") ? "rotateY(".concat(d, "deg)") : "translate3d(".concat(c, "px, 0, 0)");
                    a && (x(a.style, "TransitionTimingFunction", e), x(a.style, "TransitionDuration", "".concat(b, "ms")), x(a.style, "Transform", c)) },
                y = function(a, c, b, e) {
                    var d = 0,
                        g = 0;
                    if (a) {
                        if (a.offsetParent) {
                            do
                                if (d += a.offsetLeft,
                                    g += a.offsetTop, a === e) break; while (a = a.offsetParent)
                        }
                        c && (g -= c);
                        b && (d -= b)
                    }
                    return [d, g]
                };
            "string" === typeof m && (m = document.getElementById(m));
            m.id || m.setAttribute("id", "stories-" + Math.random().toString(36).substr(2, 9));
            var G = function(a) {
                    a = 1E3 * Number(a);
                    a = new Date(a);
                    var c = a.getTime();
                    c = ((new Date).getTime() - c) / 1E3;
                    var b = h("language", "time");
                    b = [
                        [60, " ".concat(b.seconds), 1],
                        [120, "1 ".concat(b.minute), ""],
                        [3600, " ".concat(b.minutes), 60],
                        [7200, "1 ".concat(b.hour), ""],
                        [86400, " ".concat(b.hours), 3600],
                        [172800,
                            " ".concat(b.yesterday), ""
                        ],
                        [604800, " ".concat(b.days), 86400]
                    ];
                    var e = 1;
                    0 > c && (c = Math.abs(c), e = 2);
                    for (var d = 0, g; g = b[d++];)
                        if (c < g[0]) return "string" === typeof g[2] ? g[e] : Math.floor(c / g[2]) + g[1];
                    c = a.getDate();
                    b = a.getMonth();
                    a = a.getFullYear();
                    return "".concat(c, "/").concat(b + 1, "/").concat(a)
                },
                k = m.id,
                E = {
                    skin: "snapgram",
                    avatars: !0,
                    stories: [],
                    backButton: !0,
                    backNative: !1,
                    paginationArrows: !1,
                    previousTap: !0,
                    autoFullScreen: !1,
                    openEffect: !0,
                    cubeEffect: !1,
                    list: !1,
                    localStorage: !0,
                    callbacks: {
                        onOpen: function(a, c) { c() },
                        onView: function(a) {},
                        onEnd: function(a, c) { c() },
                        onClose: function(a, c) { c() },
                        onNextItem: function(a, c, b) { b() },
                        onNavigateItem: function(a, c, b) { b() }
                    },
                    template: {
                        timelineItem: function(a) {
                            return '\n<div class="story '.concat(!0 === d(a, "seen") ? "seen" : "", '">\n<a class="item-link" href="').concat(d(a, "link"), '">\n<span class="item-preview">\n<img lazy="eager" src="').concat(h("avatars") || !d(a, "currentPreview") ? d(a, "photo") : d(a, "currentPreview"), '" />\n</span>\n<span class="info" itemProp="author" itemScope itemType="http://schema.org/Person">\n<strong class="name" itemProp="name">').concat(d(a,
                                "name"), '</strong>\n<span class="time">').concat(d(a, "lastUpdatedAgo"), '</span>\n</span>\n</a>\n\n<ul class="items"></ul>\n</div>')
                        },
                        timelineStoryItem: function(a) {
                            return '<a href="'.concat(d(a, "src"), '"\ndata-link="').concat(d(a, "link"), '"\ndata-linkText="').concat(d(a, "linkText"), '"\ndata-time="').concat(d(a, "time"), '"\ndata-type="').concat(d(a,
                                "type"), '"\ndata-length="').concat(d(a, "length"), '">\n<img loading="auto" src="').concat(d(a, "preview"), '" />\n</a>')
                        },
                        viewerItem: function(a, c) {
                            return '<div class="story-viewer">\n<div class="head">\n<div class="left">\n'.concat(h("backButton") ? '<a class="back">&lsaquo;</a>' : "", '\n\n<span class="item-preview">\n<img lazy="eager" class="profilePhoto" src="').concat(d(a,
                                "photo"), '" />\n</span>\n\n<div class="info">\n<strong class="name">').concat(d(a, "name"), '</strong>\n<span class="time">').concat(d(a, "timeAgo"), '</span>\n</div>\n</div>\n\n<div class="right">\n<span class="time">').concat(d(c, "timeAgo"), '</span>\n<span class="loading"></span>\n<a class="close" tabIndex="2">&times;</a>\n</div>\n</div>\n\n<div class="slides-pointers">\n<div class="wrap"></div>\n</div>\n\n').concat(h("paginationArrows") ?
                                '<div class="slides-pagination">\n<span class="previous">&lsaquo;</span>\n<span class="next">&rsaquo;</span>\n</div>' : "", "\n</div>")
                        },
                        viewerItemPointer: function(a, c, b) {
                            return '<span \nclass="'.concat(c === a ? "active" : "", " ").concat(!0 === d(b, "seen") ? "seen" : "", '"\ndata-index="').concat(a, '" data-item-id="').concat(d(b, "id"), '">\n<b style="animation-duration:').concat("" ===
                                d(b, "length") ? "3" : d(b, "length"), 's"></b>\n</span>')
                        },
                        viewerItemBody: function(a, c, b) {
                            return '<div \nclass="item '.concat(!0 === d(b, "seen") ? "seen" : "", " ").concat(c === a ? "active" : "", '"\ndata-time="').concat(d(b, "time"), '" data-type="').concat(d(b, "type"), '" data-index="').concat(a, '" data-item-id="').concat(d(b, "id"), '">\n').concat("video" === d(b, "type") ? '<video class="media" muted webkit-playsinline playsinline preload="auto" src="'.concat(d(b,
                                "src"), '" ').concat(d(b, "type"), '></video>\n<b class="tip muted">').concat(h("language", "unmute"), "</b>") : '<img loading="auto" class="media" src="'.concat(d(b, "src"), '" ').concat(d(b, "type"), " />\n"), "\n\n").concat(d(b, "link") ? '<a class="tip link" href="'.concat(d(b, "link"), '" rel="noopener" target="_blank">\n').concat(d(b, "linkText") && "" !== d(b, "linkText") ? d(b, "linkText") : h("language", "visitLink"), "\n</a>") :
                                "", "\n</div>")
                        }
                    },
                    language: { unmute: "Touch to unmute", keyboardTip: "Press space to see next", visitLink: "Visit link", time: { ago: "ago", hour: "hour ago", hours: "hours ago", minute: "minute ago", minutes: "minutes ago", fromnow: "from now", seconds: "seconds ago", yesterday: "yesterday", tomorrow: "tomorrow", days: "days ago" } }
                },
                z = function() {
                    var a = f("#zuck-modal");
                    a || n.Zuck.hasModal || (n.Zuck.hasModal = !0, a = document.createElement("div"), a.id = "zuck-modal", h("cubeEffect") && (a.className = "with-cube"), a.innerHTML =
                        '<div id="zuck-modal-content"></div>', a.style.display = "none", a.setAttribute("tabIndex", "1"), a.onkeyup = function(a) { a = a.keyCode;
                            27 === a ? z.close() : (13 === a || 32 === a) && z.next() }, h("openEffect") && a.classList.add("with-effects"), R(a, function() { a.classList.contains("closed") && (c.innerHTML = "", a.style.display = "none", a.classList.remove("closed"), a.classList.remove("animated")) }), document.body.appendChild(a));
                    var c = f("#zuck-modal-content"),
                        b = function(a) {
                            var b = f("#zuck-modal"),
                                c = "",
                                d = "",
                                g = 0,
                                p = f("#zuck-modal-slider-".concat(k)),
                                r = { previous: f("#zuck-modal .story-viewer.previous"), next: f("#zuck-modal .story-viewer.next"), viewing: f("#zuck-modal .story-viewer.viewing") };
                            if (!r.previous && !a || !r.next && a) return !1;
                            a ? (c = "next", d = "previous") : (c = "previous", d = "next");
                            h("cubeEffect") ? "previous" === c ? g = b.slideWidth : "next" === c && (g = -1 * b.slideWidth) : g = -1 * y(r[c])[0];
                            w(p, g, 600, null);
                            setTimeout(function() {
                                if ("" !== c && r[c] && "" !== d) {
                                    var a = r[c].getAttribute("data-story-id");
                                    e.internalData.currentStory = a;
                                    var b = f("#zuck-modal .story-viewer.".concat(d));
                                    b && b.parentNode.removeChild(b);
                                    r.viewing && (r.viewing.classList.add("stopped"), r.viewing.classList.add(d), r.viewing.classList.remove("viewing"));
                                    r[c] && (r[c].classList.remove("stopped"), r[c].classList.remove(c), r[c].classList.add("viewing"));
                                    (b = H(c)) && q(b, c);
                                    var g = e.internalData.currentStory;
                                    if (b = f('#zuck-modal [data-story-id="'.concat(g, '"]'))) {
                                        b = b.querySelectorAll("[data-index].active");
                                        var A = b[0].firstElementChild;
                                        e.data[g].currentItem = parseInt(b[0].getAttribute("data-index"), 10);
                                        b[0].innerHTML =
                                            '<b style="'.concat(A.style.cssText, '"></b>');
                                        M(b[0].firstElementChild, function() { e.nextItem(!1) })
                                    }
                                    w(p, "0", 0, null);
                                    b && (a = f('#zuck-modal .story-viewer[data-story-id="'.concat(a, '"]')), D(a, [b[0], b[1]], !0));
                                    h("callbacks", "onView")(e.internalData.currentStory)
                                }
                            }, 650)
                        },
                        q = function(a, b, c) {
                            var g = f("#zuck-modal-slider-".concat(k)),
                                q = d(a, "items");
                            a.timeAgo = q && q[0] ? G(d(q[0], "time")) : "";
                            var A = "",
                                p = "",
                                m = d(a, "id"),
                                n = document.createElement("div"),
                                l = d(a, "currentItem") || 0;
                            if (f('#zuck-modal .story-viewer[data-story-id="'.concat(m,
                                    '"]'))) return !1;
                            n.className = "slides";
                            v(q, function(b, c) { c.timeAgo = G(d(c, "time"));
                                l > b && (a.items[b].timeAgo = c.timeAgo, a.items[b].seen = !0, c.seen = !0);
                                p += h("template", "viewerItemPointer")(b, l, c);
                                A += h("template", "viewerItemBody")(b, l, c) });
                            n.innerHTML = A;
                            var t = n.querySelector("video"),
                                J = function(a) { a.muted ? u.classList.add("muted") : u.classList.remove("muted") };
                            t && (t.onwaiting = function(a) { t.paused && (u.classList.add("paused"), u.classList.add("loading")) }, t.onplay = function() {
                                J(t);
                                u.classList.remove("stopped");
                                u.classList.remove("paused");
                                u.classList.remove("loading")
                            }, t.onload = t.onplaying = t.oncanplay = function() { J(t);
                                u.classList.remove("loading") }, t.onvolumechange = function() { J(t) });
                            q = document.createElement("div");
                            q.innerHTML = h("template", "viewerItem")(a, l);
                            var u = q.firstElementChild;
                            u.className = "story-viewer muted ".concat(b, " ").concat(c ? "" : "stopped", " ").concat(h("backButton") ? "with-back-button" : "");
                            u.setAttribute("data-story-id", m);
                            u.querySelector(".slides-pointers .wrap").innerHTML = p;
                            v(u.querySelectorAll(".close, .back"),
                                function(a, b) { b.onclick = function(a) { a.preventDefault();
                                        z.close() } });
                            u.appendChild(n);
                            "viewing" === b && D(u, u.querySelectorAll('[data-index="'.concat(l, '"].active')), !1);
                            v(u.querySelectorAll(".slides-pointers [data-index] > b"), function(a, b) { M(b, function() { e.nextItem(!1) }) });
                            "previous" === b ? F(g, u) : g.appendChild(u)
                        },
                        p = function(a) {
                            var c = f("#zuck-modal"),
                                d, g = void 0,
                                q = void 0,
                                p = void 0,
                                r = void 0,
                                m = void 0,
                                k = function(b) {
                                    var e = f("#zuck-modal .viewing");
                                    if ("A" === b.target.nodeName) return !0;
                                    b.preventDefault();
                                    b = b.touches ?
                                        b.touches[0] : b;
                                    var h = y(f("#zuck-modal .story-viewer.viewing"));
                                    c.slideWidth = f("#zuck-modal .story-viewer").offsetWidth;
                                    d = h[0];
                                    g = { x: b.pageX, y: b.pageY, time: Date.now() };
                                    q = void 0;
                                    p = {};
                                    a.addEventListener("mousemove", l);
                                    a.addEventListener("mouseup", t);
                                    a.addEventListener("mouseleave", t);
                                    a.addEventListener("touchmove", l);
                                    a.addEventListener("touchend", t);
                                    e && e.classList.add("paused");
                                    S();
                                    r = setTimeout(function() { e.classList.add("longPress") }, 600);
                                    m = setTimeout(function() { clearInterval(m);
                                        m = !1 }, 250)
                                },
                                l = function(b) {
                                    var c =
                                        b.touches ? b.touches[0] : b,
                                        e = c.pageX;
                                    c = c.pageY;
                                    g && (p = { x: e - g.x, y: c - g.y }, "undefined" === typeof q && (q = !!(q || Math.abs(p.x) < Math.abs(p.y))), !q && g && (b.preventDefault(), w(a, d + p.x, 0, null)))
                                },
                                t = function I(A) {
                                    var k = f("#zuck-modal .viewing"),
                                        u = g;
                                    if (p) {
                                        var B = g ? Date.now() - g.time : void 0;
                                        B = 300 > Number(B) && 25 < Math.abs(p.x) || Math.abs(p.x) > c.slideWidth / 3;
                                        var t = 0 > p.x,
                                            v = t ? f("#zuck-modal .story-viewer.next") : f("#zuck-modal .story-viewer.previous");
                                        q || (!B || t && !v || !t && !v ? w(a, d, 300) : b(t));
                                        g = void 0;
                                        a.removeEventListener("mousemove",
                                            l);
                                        a.removeEventListener("mouseup", I);
                                        a.removeEventListener("mouseleave", I);
                                        a.removeEventListener("touchmove", l);
                                        a.removeEventListener("touchend", I)
                                    }
                                    B = e.internalData.currentVideoElement;
                                    r && clearInterval(r);
                                    k && (D(k, k.querySelectorAll(".active"), !1), k.classList.remove("longPress"), k.classList.remove("paused"));
                                    if (m)
                                        if (clearInterval(m), m = !1, k = function() { u.x > n.screen.width / 3 || !h("previousTap") ? e.navigateItem("next", A) : e.navigateItem("previous", A) }, (t = f("#zuck-modal .viewing")) && B) t.classList.contains("muted") ?
                                            N(B, t) : k();
                                        else return k(), !1
                                };
                            a.addEventListener("touchstart", k);
                            a.addEventListener("mousedown", k)
                        };
                    return {
                        show: function(a, b) {
                            var d = f("#zuck-modal");
                            h("callbacks", "onOpen")(a, function() {
                                c.innerHTML = '<div id="zuck-modal-slider-'.concat(k, '" class="slider"></div>');
                                var b = e.data[a],
                                    g = b.currentItem || 0,
                                    m = f("#zuck-modal-slider-".concat(k));
                                p(m);
                                e.internalData.currentStory = a;
                                b.currentItem = g;
                                h("backNative") && (n.location.hash = "#!".concat(k));
                                (g = H("previous")) && q(g, "previous");
                                q(b, "viewing", !0);
                                (b = H("next")) &&
                                q(b, "next");
                                h("autoFullScreen") && d.classList.add("fullscreen");
                                var l = function() { d.classList.contains("fullscreen") && h("autoFullScreen") && 1024 >= n.screen.width && L(d);
                                    d.focus() };
                                h("openEffect") ? (b = f("#".concat(k, ' [data-id="').concat(a, '"] .item-preview')), g = y(b), d.style.marginLeft = "".concat(g[0] + b.offsetWidth / 2, "px"), d.style.marginTop = "".concat(g[1] + b.offsetHeight / 2, "px"), d.style.display = "block", d.slideWidth = f("#zuck-modal .story-viewer").offsetWidth, setTimeout(function() { d.classList.add("animated") },
                                    10), setTimeout(function() { l() }, 300)) : (d.style.display = "block", d.slideWidth = f("#zuck-modal .story-viewer").offsetWidth, l());
                                h("callbacks", "onView")(a)
                            })
                        },
                        next: function(a) { h("callbacks", "onEnd")(e.internalData.currentStory, function() { var a = e.internalData.currentStory,
                                    c = f("#".concat(k, ' [data-id="').concat(a, '"]'));
                                c && (c.classList.add("seen"), e.data[a].seen = !0, e.internalData.seenItems[a] = !0, O("seenItems", e.internalData.seenItems), K());
                                f("#zuck-modal .story-viewer.next") ? b(!0) : z.close() }) },
                        close: function() {
                            var a =
                                f("#zuck-modal");
                            h("callbacks", "onClose")(e.internalData.currentStory, function() { h("backNative") && (n.location.hash = "");
                                L(a, !0);
                                h("openEffect") ? a.classList.add("closed") : (c.innerHTML = "", a.style.display = "none") })
                        }
                    }
                }(),
                C = function(a, c) {
                    var b = a.getAttribute("data-id"),
                        d = document.querySelectorAll("#".concat(k, ' [data-id="').concat(b, '"] .items > li')),
                        f = [];
                    if (!h("reactive") || c) v(d, function(a, b) {
                        var c = b.firstElementChild,
                            d = c.firstElementChild;
                        f.push({
                            id: c.getAttribute("data-id"),
                            src: c.getAttribute("href"),
                            length: c.getAttribute("data-length"),
                            type: c.getAttribute("data-type"),
                            time: c.getAttribute("data-time"),
                            link: c.getAttribute("data-link"),
                            linkText: c.getAttribute("data-linkText"),
                            preview: d.getAttribute("src")
                        })
                    }), e.data[b].items = f, (b = h("callbacks", "onDataUpdate")) && b(e.data, function() {})
                },
                P = function(a, c) {
                    var b = a.getAttribute("data-id"),
                        d = !1;
                    e.internalData.seenItems[b] && (d = !0);
                    try {
                        e.data[b] || (e.data[b] = {}), e.data[b].id = b, e.data[b].photo = a.getAttribute("data-photo"), e.data[b].name = a.querySelector(".name").innerText,
                            e.data[b].link = a.querySelector(".item-link").getAttribute("href"), e.data[b].lastUpdated = a.getAttribute("data-last-updated"), e.data[b].seen = d, e.data[b].items || (e.data[b].items = [], e.data[b].noItems = !0)
                    } catch (p) { e.data[b] = { items: [] } } a.onclick = function(a) { a.preventDefault();
                        z.show(b) };
                    (d = h("callbacks", "onDataUpdate")) && d(e.data, function() {})
                },
                H = function(a) {
                    var c = e.internalData.currentStory;
                    a = "".concat(a, "ElementSibling");
                    return c && (c = f("#".concat(k, ' [data-id="').concat(c, '"]'))[a]) ? (c = c.getAttribute("data-id"),
                        e.data[c] || !1) : !1
                },
                K = function() { v(document.querySelectorAll("#".concat(k, " .story.seen")), function(a, c) { var b = e.data[c.getAttribute("data-id")],
                            d = c.parentNode;
                        h("reactive") || d.removeChild(c);
                        e.update(b, !0) }) },
                D = function(a, c, b) {
                    var d = c[1],
                        f = c[0];
                    if (!d || !f) return !1;
                    (c = e.internalData.currentVideoElement) && c.pause();
                    if ("video" === d.getAttribute("data-type")) {
                        var g = d.getElementsByTagName("video")[0];
                        if (!g) return e.internalData.currentVideoElement = !1;
                        d = function() {
                            g.duration && x(f.getElementsByTagName("b")[0].style,
                                "AnimationDuration", "".concat(g.duration, "s"))
                        };
                        d();
                        g.addEventListener("loadedmetadata", d);
                        e.internalData.currentVideoElement = g;
                        g.play();
                        b && b.target && N(g, a)
                    } else e.internalData.currentVideoElement = !1
                },
                S = function() { var a = e.internalData.currentVideoElement; if (a) try { a.pause() } catch (c) {} },
                N = function(a, c) { a.muted = !1;
                    a.volume = 1;
                    a.removeAttribute("muted");
                    a.play();
                    a.paused && (a.muted = !0, a.play());
                    c && c.classList.remove("paused") },
                O = function(a, c) {
                    try {
                        if (h("localStorage")) {
                            var b = "zuck-".concat(k, "-").concat(a);
                            n.localStorage[b] = JSON.stringify(c)
                        }
                    } catch (q) {}
                },
                Q = function(a) { return h("localStorage") ? (a = "zuck-".concat(k, "-").concat(a), n.localStorage[a] ? JSON.parse(n.localStorage[a]) : !1) : !1 };
            e.data = h("stories") || {};
            e.internalData = {};
            e.internalData.seenItems = Q("seenItems") || {};
            e.add = e.update = function(a, c) {
                var b = d(a, "id"),
                    q = f("#".concat(k, ' [data-id="').concat(b, '"]')),
                    p = d(a, "items"),
                    g = void 0;
                g = !1;
                p[0] && (g = p[0].preview || "");
                !0 === e.internalData.seenItems[b] && (a.seen = !0);
                a.currentPreview = g;
                q ? g = q : (g = document.createElement("div"),
                    g.innerHTML = h("template", "timelineItem")(a), g = g.firstElementChild);
                !1 === a.seen && (e.internalData.seenItems[b] = !1, O("seenItems", e.internalData.seenItems));
                g.setAttribute("data-id", b);
                g.setAttribute("data-photo", d(a, "photo"));
                g.setAttribute("data-last-updated", d(a, "lastUpdated"));
                P(g);
                q || h("reactive") || (c ? m.appendChild(g) : F(m, g));
                v(p, function(a, d) { e.addItem(b, d, c) });
                c || K()
            };
            e.next = function() { z.next() };
            e.remove = function(a) { a = f("#".concat(k, ' > [data-id="').concat(a, '"]'));
                a.parentNode.removeChild(a) };
            e.addItem = function(a, c, b) { a = f("#".concat(k, ' > [data-id="').concat(a, '"]')); if (!h("reactive")) { var e = document.createElement("li"),
                        p = a.querySelectorAll(".items")[0];
                    e.className = d(c, "seen") ? "seen" : "";
                    e.setAttribute("data-id", d(c, "id"));
                    e.innerHTML = h("template", "timelineStoryItem")(c);
                    b ? p.appendChild(e) : F(p, e) } C(a) };
            e.removeItem = function(a, c) { var b = f("#".concat(k, ' > [data-id="').concat(a, '"] [data-id="').concat(c, '"]'));
                h("reactive") || m.parentNode.removeChild(b) };
            e.navigateItem = e.nextItem = function(a,
                c) {
                var b = e.internalData.currentStory,
                    d = e.data[b].currentItem,
                    k = f('#zuck-modal .story-viewer[data-story-id="'.concat(b, '"]')),
                    g = "previous" === a ? -1 : 1;
                if (!k || 1 === k.touchMove) return !1;
                var m = k.querySelectorAll('[data-index="'.concat(d, '"]')),
                    l = m[0],
                    n = m[1],
                    w = k.querySelectorAll('[data-index="'.concat(d + g, '"]')),
                    y = w[0],
                    r = w[1];
                k && y && r ? (d = (d = h("callbacks", "onNavigateItem")) ? h("callbacks", "onNavigateItem") : h("callbacks", "onNextItem"), d(b, r.getAttribute("data-story-id"), function() {
                    "previous" === a ? (l.classList.remove("seen"),
                        n.classList.remove("seen")) : (l.classList.add("seen"), n.classList.add("seen"));
                    l.classList.remove("active");
                    n.classList.remove("active");
                    y.classList.remove("seen");
                    y.classList.add("active");
                    r.classList.remove("seen");
                    r.classList.add("active");
                    v(k.querySelectorAll(".time"), function(a, b) { b.innerText = G(r.getAttribute("data-time")) });
                    e.data[b].currentItem += g;
                    D(k, w, c)
                })) : k && "previous" !== a && z.next(c)
            };
            return function() {
                m && m.querySelector(".story") && v(m.querySelectorAll(".story"), function(a, c) { P(c) });
                h("backNative") &&
                    (n.location.hash === "#!".concat(k) && (n.location.hash = ""), n.addEventListener("popstate", function(a) { n.location.hash !== "#!".concat(k) && (n.location.hash = "") }, !1));
                if (!h("reactive")) { var a = Q("seenItems"); for (c in a) a.hasOwnProperty(c) && e.data[c] && (e.data[c].seen = a[c]) } v(h("stories"), function(a, c) { e.add(c, !0) });
                K();
                a = h("avatars") ? "user-icon" : "story-preview";
                var c = h("list") ? "list" : "carousel";
                m.className += " stories ".concat(a, " ").concat(c, " ").concat("".concat(h("skin")).toLowerCase());
                return e
            }()
        };
    l.buildTimelineItem =
        function(d, f, e, h, n, w) { var m = { id: d, photo: f, name: e, link: h, lastUpdated: n, items: [] };
            v(w, function(d, e) { m.items.push(l.buildStoryItem.apply(l, e)) }); return m };
    l.buildStoryItem = function(d, f, e, h, l, n, v, x, k) { return { id: d, type: f, length: e, src: h, preview: l, link: n, linkText: v, seen: x, time: k } };
    l.buildItem = l.buildStoryItem;
    "undefined" !== typeof exports ? ("undefined" !== typeof module && module.exports && (exports = module.exports = l), exports.ZuckJS = l) : n.ZuckitaDaGalera = n.Zuck = l;
    return l
})(window);

var timestamp = function() {
    var timeIndex = 0;
    var shifts = [35, 60, 60 * 3, 60 * 60 * 2, 60 * 60 * 25, 60 * 60 * 24 * 4, 60 * 60 * 24 * 10];

    var now = new Date();
    var shift = shifts[timeIndex++] || 0;
    var date = new Date(now - shift * 1000);

    return date.getTime() / 1000;
};

var changeSkin = function(skin) {
    location.href = location.href.split('#')[0].split('?')[0] + '?skin=' + skin;
};

var getCurrentSkin = function() {
    var header = document.getElementById('header');
    var skin = location.href.split('skin=')[1];

    if (!skin) {
        skin = 'Snapgram';
    }

    if (skin.indexOf('#') !== -1) {
        skin = skin.split('#')[0];
    }

    var skins = {
        Snapgram: {
            avatars: true,
            list: false,
            autoFullScreen: false,
            cubeEffect: true,
            paginationArrows: false
        },

        VemDeZAP: {
            avatars: false,
            list: true,
            autoFullScreen: false,
            cubeEffect: false,
            paginationArrows: true
        },

        FaceSnap: {
            avatars: true,
            list: false,
            autoFullScreen: true,
            cubeEffect: false,
            paginationArrows: true
        },

        Snapssenger: {
            avatars: false,
            list: false,
            autoFullScreen: false,
            cubeEffect: false,
            paginationArrows: false
        }
    };

    var el = document.querySelectorAll('#skin option');
    var total = el.length;
    for (var i = 0; i < total; i++) {
        var what = skin == el[i].value ? true : false;

        if (what) {
            el[i].setAttribute('selected', 'selected');

            header.innerHTML = skin;
            header.className = skin;
        } else {
            el[i].removeAttribute('selected');
        }
    }

    return {
        name: skin,
        params: skins[skin]
    };
};
